import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useLocation } from 'react-router-dom';

import { CAPTCHA_SITE_KEY } from 'common/constants/html';
import { loadCaptcha } from 'common/captcha';
import routes from 'common/enums/routes';
import {
  AuthRedirect,
  DownloadAppRedirect,
  DownloadRedirect,
  ReferralRedirect,
  GetStartedRedirect,
  RedirectToDobby,
  RedirectToArticle,
  RedirectToProvider,
  Redirect
} from 'pages';

import { WithNavBar } from './components';
import RedirectToCommunity from '../pages/RedirectToCommunity';

const MainNavigation = () => {
  mixpanel.init('2d3d1599bee9be141b0fee0e5e37f5b9');
  mixpanel.track('Website opened');
  loadCaptcha(CAPTCHA_SITE_KEY);
  const location = useLocation();

  useEffect(() => {
    const searchTransformed = location?.search?.toLowerCase();
    if (
      searchTransformed?.includes('signup') &&
      searchTransformed?.includes('returnto') &&
      window.location.pathname !== routes.AUTH
    ) {
      window.location.pathname = routes.AUTH;
      return;
    }

    if ([routes.ATTIC, `${routes.ATTIC}/`].includes(window.location.pathname)) {
      window.location.pathname = routes.CHECK_THE_PRICE;
      return;
    }
  }, []);

  return (
    <Switch>
      <Route
        path={routes.GET_STARTED_REDIRECT}
        component={GetStartedRedirect}
      />
      <Route path={routes.DOWNLOAD} component={DownloadRedirect} />
      <Route path={routes.DOWNLOAD_DOBBY_APP} component={DownloadAppRedirect} />
      <Route path={routes.DOBBY_IN_APP_LINK} component={DownloadAppRedirect} />
      <Route
        path={routes.DOWNLOAD_DOBBY_PRO_APP}
        component={DownloadAppRedirect}
      />
      <Route path={routes.REDIRECT} component={Redirect} />
      <Route
        path={[routes.REDIRECT_DOBBY_ARTICLE, routes.REDIRECT_DOBBY_CHECKLIST]}
        component={RedirectToArticle}
      />
      <Route
        path={[routes.REDIRECT_DOBBY_COMMUNITY]}
        component={RedirectToCommunity}
      />
      <Route path={routes.REDIRECT_DOBBY} component={RedirectToDobby} />
      <Route path={routes.REDIRECT_PROVIDER} component={RedirectToProvider} />
      <Route
        path={[
          routes.REFERRAL_HOMEOWNER,
          routes.EMPTY_REFERRAL_HOMEOWNER1,
          routes.EMPTY_REFERRAL_HOMEOWNER2,
          routes.EMPTY_REFERRAL_HOMEOWNER3
        ]}
        render={props => <ReferralRedirect appName="hellodobby" {...props} />}
      />
      <Route
        path={[routes.REFERRAL_PROVIDER, routes.EMPTY_REFERRAL_PROVIDER]}
        render={props => <ReferralRedirect appName="dobbypro" {...props} />}
      />
      <Route path={routes.AUTH} component={AuthRedirect} />
      <Route path="*" component={WithNavBar} />
    </Switch>
  );
};

export default withRouter(MainNavigation);
