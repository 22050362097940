import { useEffect } from 'react';
import links from '../../common/constants/links';

const RedirectToCommunity = ({ location: { search } }) => {
  useEffect(() => {
    const deepLinkTimeout = setTimeout(() => {
      window.location.href = 'https://community.dobbyhome.com/';
    }, 2000);

    window.location.href = `${links.HO_DEEP_LINK_BASE}community`;

    return () => clearTimeout(deepLinkTimeout);

    // window.location.href = `${links.HO_DEEP_LINK_BASE}community`;
    // window.location.href = 'https://community.dobbyhome.com/';
  }, []);

  return null;
};

export default RedirectToCommunity;
